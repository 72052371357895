<template>
  <div>
    <!-- <h2 class="pagetitle__title text-start">課程 / 30SOLIDWORKS零件與組合件培訓教材</h2> -->
    <h2 class="pagetitle__title text-start">上傳課程影片</h2>
    <!-- <div class="courseOverview">
      <div class="row mt-4">
        <div class="col-md-4 mb-4">
          <div class="card courseOverview__card">
            <div class="card-body text-start">
              <h5 class="card-title mb-3">學生人數</h5>

              <div class="d-flex align-items-center">
                <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                  <i class="bi bi-people-fill"></i>
                </div>
                <div class="ps-3">
                  <h6 class="card-strong">145<span>人</span></h6>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-4">
          <div class="card courseOverview__card">
            <div class="card-body text-start">
              <h5 class="card-title mb-3">銷售額</h5>

              <div class="d-flex align-items-center">
                <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                  <i class="bi bi-cart"></i>
                </div>
                <div class="ps-3">
                  <h6 class="card-strong">$<span>200</span></h6>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-4">
          <div class="card courseOverview__card">
            <div class="card-body text-start">
              <h5 class="card-title mb-3">評論數</h5>

              <div class="d-flex align-items-center">
                <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                  <i class="bi bi-chat-left-dots-fill"></i>
                </div>
                <div class="ps-3">
                  <h6 class="card-strong">10<span>則</span></h6>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5 mb-4">
          <div class="card courseOverview__card">
            <div class="card-body text-start">
              <h5 class="card-title mb-3">評價</h5>
               <h5 class="card-title mb-3"><strong style="font-size: 2.5rem;">4.5 </strong>Stars</h5>
              <div>
                <apexchart width="100%" type="bar" :options="chartOptions" :series="series"></apexchart>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-7 mb-4">
          <div class="card courseOverview__card">
            <div class="card-body text-start">
              <h5 class="card-title mb-3">評論列表</h5>
              <div class="courseOverview__box">
                <ul>
                  <li class="courseOverview__list">
                    <h4 class="mb-2">標題</h4>
                    <p>txtxtxtxtxtxtxt</p>
                  </li>
                  <li class="courseOverview__list">
                    <h4 class="mb-2">標題</h4>
                    <p>txtxtxtxtxtxtxt</p>
                  </li>
                  <li class="courseOverview__list">
                    <h4 class="mb-2">標題</h4>
                    <p>txtxtxtxtxtxtxt</p>
                  </li>
                  <li class="courseOverview__list">
                    <h4 class="mb-2">標題</h4>
                    <p>txtxtxtxtxtxtxt</p>
                  </li>
                  <li class="courseOverview__list">
                    <h4 class="mb-2">標題</h4>
                    <p>txtxtxtxtxtxtxt</p>
                  </li>
                  <li class="courseOverview__list">
                    <h4 class="mb-2">標題</h4>
                    <p>txtxtxtxtxtxtxt</p>
                  </li>
                  <li class="courseOverview__list">
                    <h4 class="mb-2">標題</h4>
                    <p>txtxtxtxtxtxtxt</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div> -->
    <div>
      <h5 class="text-start mt-4"><i class="fas fa-info-circle me-2"></i>上傳檔案大小上限: 2 MB</h5>
      <button class="btn btn-main d-block mt-4" @click.prevent="startCreate = true;testCreateVideo()">開始上傳檔案</button>
      <form method="POST" :action="uploadLink" enctype="multipart/form-data" v-if="startCreate" class="mt-4 d-flex" @submit="uploading">
        <!-- <label for="file">File:</label>
        <input type="file" name="file_data" id="file"><br> -->
        <label for="file" class="form-label"></label>
        <input class="form-control w-50" type="file" id="file" name="file_data" ref="file">
        <input type="submit" name="submit" value="上傳" class="btn btn-main">
      </form>
      <img src="../assets/images/videoUpload.svg" alt="" width="220" class="mt-4" v-if="completed == false">
      <div v-if="completed == true" class="mt-5">
        <iframe :src="`https://player.vimeo.com/video/${videoId}`" frameborder="0"></iframe>
        <p class="mt-4" v-text="`網址：https://player.vimeo.com/video/${videoId}`"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Breadcrumb from '../components/Breadcrumb';

export default {
  data() {
    return {
      id: '',
      chartOptions: {
        colors: ['#012970'],
        chart: {
          id: 'vuechart-example',
        },
        xaxis: {
          categories: ['5 Stars', '4 Stars', '3 Stars', '2 Stars', '1 Stars'],
        },
        plotOptions: {
          bar: {
            borderRadius: 2,
            horizontal: true,
          },
        },
      },
      series: [{
        name: 'series-1',
        data: [12, 10, 8, 6, 4],
      }],
      uploadLink: '',
      startCreate: false,
      videoId: '',
      completed: false,
    };
  },
  components: {
    Breadcrumb,
  },
  methods: {
    test() {
      this.$http.get('https://api.vimeo.com/tutorial', {
        headers: {
          Authorization: 'bearer 9b2dc6c8883bc9139f2834d5647be225',
        },
      }).then((res) => {
        this.$log.debug(res.data);
      }).catch((err) => {
        this.$log.debug(err.response);
      });
    },
    testCreateVideo() {
      this.$http.post('https://api.vimeo.com/me/videos', {
        upload: {
          approach: 'post',
          size: '80000',
          redirect_url: 'http://localhost:8080/dashboard/overview/1',
        },
      }, {
        headers: {
          Authorization: 'bearer 9b2dc6c8883bc9139f2834d5647be225',
          'Content-Type': 'application/json',
          Accept: 'application/vnd.vimeo.*+json;version=3.4',
        },
      }).then((res) => {
        this.$log.debug(res.data.upload);
        this.$log.debug(res.data.upload.upload_link);
        this.uploadLink = res.data.upload.upload_link;
      }).catch((err) => {
        this.$log.debug(err.response);
      });
    },
    testUpload() {
      const uploadedFile = this.$refs.file.files[0];
      const formData = new FormData();
      formData.append('file', uploadedFile);
      this.$http.post(`${this.uploadLink}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
        },
      }).then((res) => {
        this.$log.debug(res.data);
      }).catch((err) => {
        this.$log.debug(err.response);
      });
    },
    uploading() {
      this.updateLoading(true);
    },
    ...mapActions(['updateLoading']),
  },
  created() {
    this.id = this.$route.params.id;
    this.videoId = this.$route.query.video_uri;
    if (this.videoId) {
      this.$log.debug(this.videoId.split('/')[2]);
      this.videoId = this.videoId.split('/')[2];
      this.completed = true;
    }
    this.test();
  },
};
</script>
